import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import '../utils/normalize.css';
import '../utils/css/screen.css';
import styled, { keyframes } from 'styled-components';
import { headShake, bounceInRight } from 'react-animations';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import Bio from "../components/bio";
import PostCard from '../components/postCard';

import { Icon, InlineIcon } from '@iconify/react';
import pythonIcon from '@iconify/icons-cib/python';
import djangoIcon from '@iconify/icons-cib/django';
import reactIcon from '@iconify/icons-cib/react';
import gatsbyIcon from '@iconify/icons-cib/gatsby';
import fiverrIcon from '@iconify/icons-simple-icons/fiverr';
import freelancerIcon from '@iconify/icons-simple-icons/freelancer';
import googleDevelopersIcon from '@iconify/icons-logos/google-developers-icon';
import acmIcon from '@iconify/icons-cib/acm';
import ScrollAnimation from 'react-animate-on-scroll';
// import "../utils/global.scss";
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/fold-out-animation';

// import "semantic-ui-css/semantic.min.css";
import GatsbyImage from 'gatsby-image';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { format } from "prettier";
import 'animate.css/animate.min.css';
// const fiverLogo = require("./../icons/fiverr.png");
const nucesLogo = require('./../icons/nuces.png');
// const djangoLogo = require("./../icons/django.ico");
// const reactLogo = require("./../icons/react.ico");
// const pythonLogo = require("./../icons/python.png");
// const javascriptLogo = require("./../icons/javascript.png");

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allFile.nodes;
  const HeadShake = styled.div`
    animation: 2s ${keyframes`${headShake}`};
  `;
  const Bounce = styled.div`
    animation: 2s ${keyframes`${bounceInRight}`};
  `;
  let postCounter = 0;
  // console.log(data);
  return (
    <Layout title={siteTitle}>
      <SEO
        title="Home"
        keywords={[`devlog`, `blog`, `gatsby`, `javascript`, `react`]}
      />
      <h2
        id="page-head-title"
        style={{
          padding: '1rem 0',
          textAlign: 'center'
        }}
      >
        Projects
      </h2>
      <div className="post-feed">
        {posts.map(node => {
          postCounter++;
          // console.log(node);
          if (node.sourceInstanceName === 'blog') {
            return (
              <PostCard
                key={node.children[0].fields.slug}
                count={postCounter}
                node={node.children[0]}
                sourceInstanceName={node.sourceInstanceName}
                postClass={`post`}
              />
            );
          }
        })}
      </div>
      <hr />
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    nucesLogo: allFile(
      limit: 100
      filter: { sourceInstanceName: { eq: "assets" }, name: { eq: "nuces" } }
    ) {
      totalCount
      nodes {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
        name
      }
    }
    slides: allFile(
      limit: 100
      filter: { sourceInstanceName: { eq: "home-slides" } }
    ) {
      totalCount
      nodes {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        social {
          fiverr
          freelancer
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
            tags

            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allFile(
      filter: { ext: { eq: ".md" } }
      limit: 100
      sort: { fields: modifiedTime, order: DESC }
    ) {
      nodes {
        id
        children {
          id
          ... on MarkdownRemark {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date
              description
              tags
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 1360) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        relativePath
        relativeDirectory
        ext
        sourceInstanceName
      }
    }
  }
`;
export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
);
